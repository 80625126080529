import React from "react";
import styled from "styled-components";

const Anunciante = styled.section`
    margin-top: 15px;
    padding: 10px;
    background-color: #e5e5e58c;
    border-radius: 5px;
`;

const TextoNormal = styled.span`
    color: #707070;
    font-size: 14px;
    font-weight: 400;
`;

const TituloAnunciante = styled.section`
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #707070;
`;

export default function HoverButtom({ 
  advertiserName,
  advertiserLastName,
  stateName,
  stateUF,
  sinceDate
}) {
  //const width = window.innerWidth;
  return (
    <Anunciante>
    <TituloAnunciante>Anunciante</TituloAnunciante>
        {
            (advertiserName) ?
            <>
                <TextoNormal>{advertiserName} {advertiserLastName}</TextoNormal><br/>
                <TextoNormal>{stateName}/{stateUF}</TextoNormal> <br/>
                <TextoNormal>No Kite Radar desde {new Date(sinceDate).toLocaleString('default', { month: 'long' })} de {new Date(sinceDate).getFullYear()}</TextoNormal>
            </>
            :
            null
        }
    </Anunciante>
  );
}
