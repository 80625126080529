
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import HoverButton from '../components/floatBoxStoreButton';
import AdvertiserBox from '../components/advertiserBox';

require('dotenv').config();

const Title = styled.section`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #fff;
`;

const Wrapper = styled.div`
  background: #0063ff;
  height: 60px;
  display: flex;
  padding-left: 15px;
  align-items: center;
`;

const Corpo = styled.section`
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: 15px;
`;

const TituloCorpo = styled.section`
    color: #707070;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
`;

const TituloCorpo2 = styled.section`
    color: #0063ff;
    font-size: 14px;
    font-weight: 700;
    padding-top: 15px;
`;

const TituloAnunciante = styled.section`
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #707070;
`;

const DadosPublicacao = styled.section`
    padding-top: 10px;
    color: #707070;
    font-size: 12px;
`;

const TextoNormal = styled.span`
    color: #707070;
    font-size: 14px;
    font-weight: 400;
`;

const TextoDestaque = styled(TextoNormal)`
    font-weight: 700;
`;

const Anunciante = styled.section`
    margin-top: 15px;
    padding: 10px;
    background-color: #e5e5e58c;
    border-radius: 5px;
`;

const Status = styled.section`
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    color: #fff;
    background-color: ${props => props.inputColor || "#27AE60"};
    border-radius: 10px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
`;

const ImgPrincipalDiv = styled.div`  
     display: flex;
     justify-content: center; 
     background-color: #000000;
     overflow: hidden;
     height: 300px;
     `

const OcurImg = styled.img`
    display: inline !important;
    margin: 0 auto !important;
    height: 100% !important;
    width: auto !important;
`;

const Loading = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`


export default function Occurrence() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState(null);
    const [imgSrc, setImgSrc] = useState(null);
    let history = useHistory();
    const [imgIsOpen, setImgIsOpen] = useState(false);
    const [indexImg, setIndexImg] = useState(0);
    const [imgLojaIsOpen, setImgLojaIsOpen] = useState(true);

    const { token } = useParams();


    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/occurrence/view?token=${token}`, {
            method: 'GET',
            headers: new Headers({ 'Authorization': `Bearer ${process.env.REACT_APP_AUTORIZATION}` })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result || result.length === 0 || result.error) {
                        history.push("/notfound");
                    }
                    setIsLoaded(true);
                    setItem(result);
                    document.title = 'KiteRadar - Ocorrência';
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    function fotoClick(index) {
        setIndexImg(index);
        !imgIsOpen && setImgIsOpen(true);
    }

    return (item ? <><Wrapper>
        <Title>
            Ocorrência
        </Title>
    </Wrapper>
        {
            item?.map(element => {
                return <div>
                    {/* <ImagemPrincipal src={element.equipment ? element.equipment?.equipment_photos[0]?.photo.url : ''}>
                    </ImagemPrincipal> */}
                    {(element?.equipment?.equipment_photos && element.equipment?.equipment_photos.length > 0)
                        ?
                        <>
                            <Carousel
                                statusFormatter={(currentStatus, totalStatus) => <div>{currentStatus} de {totalStatus}</div>}
                                dynamicHeight={false}
                                swipeable={true}
                                autoPlay={false}
                                showThumbs={false}
                                emulateTouch={true}
                                onClickItem={fotoClick}>
                                {
                                    element.equipment.equipment_photos.map((foto, index) => (
                                        <ImgPrincipalDiv key={index}>
                                            <OcurImg src={foto.photo.url} />
                                        </ImgPrincipalDiv>
                                    ))
                                }
                            </Carousel>
                            {imgIsOpen && (
                                <Lightbox
                                    imageLoadErrorMessage={<div>Não foi possível carregar a imagem.</div>}
                                    mainSrc={element.equipment.equipment_photos[indexImg].photo.url}
                                    nextSrc={element.equipment.equipment_photos[(indexImg + 1) % element.equipment.equipment_photos.length].photo.url}
                                    prevSrc={element.equipment.equipment_photos[(indexImg + element.equipment.equipment_photos.length - 1) % element.equipment.equipment_photos.length].photo.url}
                                    onCloseRequest={() => setImgIsOpen(false)}
                                    onMovePrevRequest={() =>
                                        setIndexImg((indexImg + element.equipment.equipment_photos.length - 1) % element.equipment.equipment_photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setIndexImg((indexImg + 1) % element.equipment.equipment_photos.length)
                                    }
                                />
                            )}</>
                        : null}
                {imgLojaIsOpen ?
                    <HoverButton 
                        _setImgLojaIsOpen = {() => setImgLojaIsOpen(false)} 
                    />
                : null}
                    <Corpo>
                        <Status inputColor={element?.event?.button_color}>{element?.event?.description}</Status>
                        <TituloCorpo>
                            {element?.equipment?.name}
                        </TituloCorpo>
                        <DadosPublicacao>Publicado em {new Date(element?.created_at).toLocaleDateString()} as {new Date(element?.created_at).toLocaleTimeString().substring(0, 5)}</DadosPublicacao>
                        <TituloCorpo2>Número de Série</TituloCorpo2>
                        <TextoNormal>{element?.equipment?.serial_number}</TextoNormal>
                        <TituloCorpo2>Local da Ocorrência</TituloCorpo2>
                        <TextoNormal>{element?.beach?.name ? `${element?.beach?.name}, `: ''}{element?.location?.city?.name}/{element?.beach?.location?.state?.uf || element?.location?.state?.uf} {new Date(element?.occurrence_date).toLocaleDateString()}</TextoNormal>
                        <TituloCorpo2>Descrição da Ocorrência</TituloCorpo2>
                        <TextoNormal>{element?.description}</TextoNormal>
                        <TituloCorpo2>Ficha Técnica</TituloCorpo2>
                        <div>
                            <TextoDestaque>Ano: </TextoDestaque>
                            <TextoNormal>{element?.equipment?.year}</TextoNormal>
                        </div>
                        <div>
                            <TextoDestaque>Categoria: </TextoDestaque>
                            <TextoNormal>{element?.equipment?.category?.description}</TextoNormal>
                        </div>
                        <div>
                            <TextoDestaque>Marca: </TextoDestaque>
                            <TextoNormal>{element?.equipment?.brand?.description}</TextoNormal>
                        </div>
                        <div>
                            <TextoDestaque>Modelo: </TextoDestaque>
                            <TextoNormal>{element?.equipment?.model?.description}</TextoNormal>
                        </div>
                        {
                            element?.equipment?.properties?.map(prop => {
                                return <div>
                                    <TextoDestaque>{prop.propertie_name.description}: </TextoDestaque>
                                    <TextoNormal>{prop.propertie_value.description}</TextoNormal>
                                </div>
                            })
                        }
                        {
                            (element?.equipment?.current_owner) ?            
                            <AdvertiserBox
                                advertiserName={element.equipment?.current_owner?.name}
                                advertiserLastName={element.equipment?.current_owner?.last_name}
                                stateName={element?.equipment?.current_owner?.user_location?.state?.name}
                                stateUF={element?.equipment?.current_owner?.user_location?.state?.uf}
                                sinceDate={element?.equipment?.current_owner?.created_at}
                            /> :
                            null
                        }
                    </Corpo>
                </div>
            })
        }
    </> : <Loading>
        <img src="../../loading.gif" alt="" style={{width: "15%"}}/>
    </Loading>);
}