import React, { useEffect, useState } from "react";
import "./style.css";
import ReactSpeedometer from "react-d3-speedometer";
import logo from "../assets/logo.svg";
import qrcode from "../assets/qrcode.svg";
import api from "../services/api";
import { useParams, useSearchParams, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import LogoForehead from "../components/logoForehead";
import styled from "styled-components";
require("dotenv").config();

export default function Dashboard() {
  const [radarDash, setRadarDash] = useState("");
  const [radarLocation, setRadarLocation] = useState("");
  //const [idDashboard, setIdDashboard] = useState();
  const [publicidade, setPublicidade] = useState(false);
  const [locationName, setLocationName] = useState("KiteRadar");
  //const [currentMeasure, setCurrentMeasure] = useState("0 Nós");
  //const [valueTextFontSize, setValueTextFontSize] = useState("30px");

  //const [idRadar, setIdRadar] = useState();
  const [imgLojaIsOpen, setImgLojaIsOpen] = useState(true);
  const REFRESH_TIMEOUT = 300000;
  const MEASURE_FONT_SIZE_PERCENT = 0.07;
  const TOKEN_EXPIRATION = 900000;
  const width = window.innerWidth;
  const height = window.innerHeight;
  const [color, setColor] = useState("#2275FF");
  const { token } = useParams();

  let history = useHistory();

  const Loading = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  `;
  const api_request = async () => {
    let id_location;

    let id, iat, exp, origin;

    const updateVariables = (obj) => {
      id = obj.id;
      iat = obj.iat;
      exp = obj.exp;
      origin = obj.origin;
    };

    try {
      const { id, iat, exp, origin } = jwt_decode(token);
      updateVariables({ id, iat, exp, origin });
    } catch (err) {
      console.error(err);
    }

    if (!id || origin !== "dashboard") {
      await api
        .get(`/location/?url_alias=${token}`, {
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_AUTORIZATION}`,
          },
        })
        .then((response) => {
          if (
            !response ||
            response.length === 0 ||
            response.error ||
            !response.data[0]?.id
          ) {
            history.push("/notfound");
          }
          setRadarLocation(response.data);
          document.title = response.data[0]?.campaign?.title
            ? `Kiteradar - ${response.data[0]?.campaign?.title}`
            : `Dashboard`;
          setLocationName(`${response.data[0]?.spot_name}, ${response.data[0]?.beach.name}
        ${response.data[0]?.beach.location.city.name}/${response.data[0]?.beach.location.state.uf}`);
          id_location = response.data[0]?.id;
        })
        .catch((e) => {
          console.log("erro =>", e);
        });
    } else {
      const now = new Date();
      const due = exp > iat ? exp : iat + TOKEN_EXPIRATION;

      if (!id || origin !== "dashboard") {
        history.push("/notfound");
      }

      if (due < now.getTime()) {
        history.push("/expired");
      }

      await api
        .get(`/location/?id_local=${Number(id)}`, {
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_AUTORIZATION}`,
          },
        })
        .then((response) => {
          if (!response || response.length === 0 || response.error) {
            history.push("/notfound");
          }
          setRadarLocation(response.data);
          document.title = response.data[0]?.campaign?.title
            ? `Kiteradar - ${response.data[0]?.campaign?.title}`
            : `Dashboard`;
          setLocationName(`${response.data[0]?.spot_name}, ${response.data[0]?.beach.name}
        ${response.data[0]?.beach.location.city.name}/${response.data[0]?.beach.location.state.uf}`);
        })
        .catch((e) => {
          console.log("erro =>", e);
        });

      id_location = id;
    }

    await api
      .get(`/v2/radar/dashboard/${Number(id_location)}`, {
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_AUTORIZATION}`,
        },
      })
      .then((response) => {
        if (!response || response.length === 0) {
          history.push("/notfound");
        }
        setRadarDash(response.data);
        setColor(response.data?.instant_measure?.color);
      })
      .catch((e) => {
        console.log("erro =>", e);
      });
  };

  useEffect(() => {
    if (token) {
      api_request();
      setInterval(() => {
        api_request();
      }, REFRESH_TIMEOUT);

      return clearInterval();
    } else {
      history.push("/notfound");
    }
  }, [token]);

  return radarLocation ? (
    <>
      {radarDash && (
        <div className="App">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></div>
          <div id="Container">
            <header className="App-header">
              {imgLojaIsOpen ? (
                <LogoForehead
                  _setImgLojaIsOpen={() => setImgLojaIsOpen(false)}
                />
              ) : null}
              <h3 id="nos-h3">Baixe Agora!</h3>
              <h1 id="nos-h1">{locationName}</h1>
              <h1 id="title-now">AGORA</h1>
              <div className="none" id="dashboard-speedometer">
                {radarDash && (
                  <>
                    <ReactSpeedometer
                      width={width * 0.9}
                      height={width * 0.51}
                      value={radarDash?.instant_measure?.sustained}
                      currentValueText="${value} Nós"
                      //currentValueText={currentMeasure}
                      valueTextFontSize={`${parseInt(
                        width * MEASURE_FONT_SIZE_PERCENT
                      )}px`}
                      paddingVertical={parseInt(width * 0.05)}
                      textColor={"#000"}
                      maxValue={40}
                      segments={1}
                      segmentColors={
                        radarDash?.instant_measure.color
                          ? [radarDash?.instant_measure.color]
                          : [`${color}`]
                      }
                      customSegmentLabels={[
                        {
                          position: "OUTSIDE",
                          color: "#d8dee9",
                        },
                      ]}
                      ringWidth={width * 0.15}
                      needleHeightRatio={0.9}
                      needleTransitionDuration={4000}
                      needleTransition="easeElastic"
                      needleColor={"black"}
                    />
                  </>
                )}
              </div>
              <div className="info-texts">
                <h3
                  id="nos-h3"
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {radarDash?.instant_measure?.cardeal}{" "}
                  {radarDash?.instant_measure?.degrees + "°"}
                </h3>
                <h3 id="nos-h3">
                  {radarDash?.instant_measure?.wind_alignment}
                </h3>
                <h3 id="nos-h4">
                  Medição:{" "}
                  {new Date(
                    radarDash?.instant_measure?.last_measure
                  ).toLocaleDateString("pt-BR")}{" "}
                  {new Date(
                    radarDash?.instant_measure?.last_measure
                  ).toLocaleTimeString("pt-BR")}
                </h3>
                {publicidade && (
                  <div
                    style={{
                      backgroundColor: "#D00000",
                      height: height * 0.2,
                      maxHeight: height * 0.2,
                    }}
                  >
                    <img src={logo} alt="" />
                  </div>
                )}
              </div>
            </header>

            <div id="App-content" style={{ backgroundColor: "#FFFFFF" }}>
              <h1 id="nos-h3">
                <strong>ÚLTIMA HORA</strong>
                <br></br>
                (Entre as{" "}
                {new Date(radarDash?.instant_measure?.last_measure).getHours() -
                  1}
                H e as{" "}
                {new Date(radarDash?.instant_measure?.last_measure).getHours()}
                H)
              </h1>
              <div className="boxes">
                <div id="box" style={{ backgroundColor: "#8ED000" }}>
                  <div id="value-box">
                    {radarDash?.last_hour_cards?.sustained}
                  </div>
                  <div id="legend-box">Nós</div>
                  <div id="box-text-footer">Média </div>
                </div>
                <div id="box" style={{ backgroundColor: "#D00000" }}>
                  <div id="value-box">{radarDash?.last_hour_cards?.gust}</div>
                  <div id="legend-box">Nós</div>
                  <div id="box-text-footer">Rajada </div>
                </div>
                <div id="box" style={{ backgroundColor: "#B4B0B0" }}>
                  <div id="value-box">
                    {radarDash?.last_hour_cards?.radar_directions?.cardeal}
                  </div>
                  <div id="legend-box">
                    {radarDash?.last_hour_cards?.radar_directions?.degrees}º
                  </div>
                  <div id="box-text-footer">Direção </div>
                </div>
              </div>
              <div id="qrcode">
                <img src={qrcode} alt="" />
                <h1>kiteradar.com.br</h1>
              </div>
            </div>
            <br></br>
            {/* )} */}
          </div>
        </div>
      )}
    </>
  ) : (
    <Loading>
      <img src="../../loading.gif" alt="" style={{ width: "15%" }} />
    </Loading>
  );
}
