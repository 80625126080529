import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HoverButton from "../components/floatBoxStoreButton";
import "./style.css";

require("dotenv").config();

// Wrapper for the carousel and logo
const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

// View for the logo at the bottom center
const LogoView = styled.div`
  position: absolute;
  bottom: 100px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

// Floating logo styles
const FloatingLogo = styled.img`
  width: 250px; /* Adjust as needed */
  /* Add additional styling as needed */
`;

// Floating buttons
const HoverButtonsView = styled.div`
  position: absolute;
  top: 25px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10; /* Ensure it's on top of other elements */
`;

const Loading = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const CarouselComponent = () => {
  const history = useHistory(); // Get history object for navigation
  const [imgSrc, setImgSrc] = useState(null);
  const [imgLogo, setImgLogo] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showHoverButton, setShowHoverButton] = useState(true);
  const { url_alias } = useParams();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/accreditors?url_alias=${url_alias}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${process.env.REACT_APP_AUTORIZATION}`,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => res[0])
      .then(
        (result) => {
          if (
            !result ||
            result.length === 0 ||
            !Array.isArray(result?.accreditor_forehead)
          ) {
            console.log("REDIRECT");
            setIsLoaded(true);
            history.push("/notfound"); // Use history.push to redirect
          } else {
            setImgSrc(result.accreditor_forehead.map((el) => el.url));
            setImgLogo(result?.accreditor_logo?.[0]?.url ?? null);
            setIsLoaded(true);
            document.title =
              result?.ad_user?.store[0]?.name || "WindRadar - Inscreva-se";
          }
        },
        (error) => {
          setIsLoaded(true);
          console.error(error);
        }
      );
  }, [url_alias]);

  return (
    <>
      {imgSrc ? (
        <CarouselWrapper>
          {showHoverButton && (
            <HoverButtonsView>
              <HoverButton
                messageText={`Para se inscrever você precisar instalar o aplicativo. \nInstale agora!`}
                _setImgLojaIsOpen={() => {
                  setShowHoverButton(false);
                  console.log("click");
                }}
              />
            </HoverButtonsView>
          )}
          <Carousel
            infiniteLoop
            animationHandler={"fade"}
            swipeable={false}
            autoPlay
            interval={3000}
            transitionTime={1000}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            emulateTouch
          >
            {imgSrc.map((item, index) => (
              <div key={index}>
                <img src={item} alt={`Image-${index + 1}`} />
              </div>
            ))}
          </Carousel>
          {imgLogo && (
            <LogoView>
              <FloatingLogo src={imgLogo} alt="Logo" />
            </LogoView>
          )}
        </CarouselWrapper>
      ) : (
        <Loading>
          <img src="/loading.gif" alt="Loading" style={{ width: "15%" }} />
        </Loading>
      )}
    </>
  );
};

export default CarouselComponent;
