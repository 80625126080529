import React, { useEffect, useState, Component } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import HoverButton from "../components/floatBoxStoreButton";
import AdvertiserBox from "../components/advertiserBox";

require("dotenv").config();

const Title = styled.section`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: ${(props) => props.color || "#fff"};
`;

const Wrapper = styled.div`
  background: ${(props) => props.backgroundColor || "#0063ff"};
  height: 60px;
  display: flex;
  padding-left: 15px;
  align-items: center;
`;

const Corpo = styled.section`
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin: 15px;
`;

const TituloCorpo = styled.section`
  color: #707070;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
`;

const TituloCorpo2 = styled.section`
  color: #0063ff;
  color: ${(props) => props.color || "#0063ff"};
  font-size: 14px;
  font-weight: 700;
  padding-top: 15px;
`;

const Preco = styled.section`
  color: #000000;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
`;

const TextoPequeno = styled.section`
  padding-top: 10px;
  color: #707070;
  font-size: 14px;
`;

const AnuncioProfissional = styled(TextoPequeno)`
  padding-top: 0px;
  padding-bottom: 10px;
  text-align: center;
`;

const TextoNormal = styled.span`
  color: #707070;
  font-size: 14px;
  font-weight: 400;
`;

const TextoDestaque = styled(TextoNormal)`
  font-weight: 700;
`;

const Loading = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export default function Accreditation() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [item, setItem] = useState(null);

  let history = useHistory();

  const { token } = useParams();
  console.log("DEV", token);

  useEffect(() => {
    const unique_code = token;

    fetch(
      //   `${process.env.REACT_APP_API_BASE_URL}/v1/accreditation/${unique_code}`,
      `${"http://192.168.0.123:3333"}/accreditation/${unique_code}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${process.env.REACT_APP_AUTORIZATION}`,
        }),
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          if (!result || result.length === 0 || result.error) {
            history.push("/notfound");
          }
          console.log(result);

          const { deleted, inactive, in_debt, pending, expired } = result;
          const state =
            !deleted && !inactive && !in_debt && !pending && !expired;

          setIsValid(state);

          setIsLoaded(true);
          setItem(result);
          document.title = "Validação de Credencial";
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
      .catch((err) => {
        console.error(err);
      });
  }, [token]);

  return isLoaded && item?.unique_code ? (
    <>
      <Wrapper backgroundColor={item?.ad_user?.store[0]?.theme_color}>
        <Title color={item?.ad_user?.store[0]?.header_color}>
          {"Validar Credencial"}
        </Title>
      </Wrapper>
      <Corpo>
        <TituloCorpo>{item.unique_code}</TituloCorpo>
        <TituloCorpo>{item.due_date}</TituloCorpo>
        <TituloCorpo>{item.situation}</TituloCorpo>
      </Corpo>
    </>
  ) : (
    <Loading>
      <img src="../../loading.gif" alt="" style={{ width: "15%" }} />
    </Loading>
  );
}
