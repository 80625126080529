import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HoverButton from "../components/floatBoxStoreButton";

require("dotenv").config();

const Background = styled.section`
  background-position-x: -135px;
  background-size: 2176px 1088px;
  background: url(${(props) => props.back});
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: start;
`;

const Svg = styled.svg`
  padding-top: 15px;
`;

const CenterFlex = styled.section`
  font-family: Montserrat;
  padding-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-horizontal: 20;
  width: "100%";
`;

const TextTitle = styled.section`
  font-family: Montserrat;
  font-size: 60px;
  font-style: bold;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  color: #fff;
  padding: 20px;
`;

const TextBody = styled.section`
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  text-align: center;
  font-weight: 550;
  letter-spacing: 0em;
  letter-spacing: 0em;
  color: #fff;
  padding: 40px;
`;

const Loading = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export default function Store() {
  const [imgSrc, setImgSrc] = useState(null);
  const [imgLojaIsOpen, setImgLojaIsOpen] = useState(true);

  useEffect(() => {
    fetch("back_404.jpg", {
      method: "GET",
    }).then((response) => {
      document.title = "KiteRadar - DownLoad";
      console.log(response.url);
      if (response.url) {
        setTimeout(() => {
          setImgSrc(response.url);
        }, 1000);
      }
    });
  });

  return (
    <>
      {imgLojaIsOpen ? (
        <HoverButton _setImgLojaIsOpen={() => setImgLojaIsOpen(false)} />
      ) : null}
      {imgSrc ? (
        <Background back={imgSrc}>
          <CenterFlex>
            <TextTitle>Ops!</TextTitle>
            <TextBody>
              Para visualizar esta página é preciso baixar o aplicativo.
            </TextBody>
          </CenterFlex>
        </Background>
      ) : (
        <Loading>
          <img src="loading.gif" alt="" style={{ width: "15%" }} />
        </Loading>
      )}
    </>
  );
}
