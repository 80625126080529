
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import HoverButton from '../components/floatBoxStoreButton';
import AdvertiserBox from '../components/advertiserBox';

require('dotenv').config();

const Title = styled.section`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: ${props => props.color || "#fff"};

`;

const Wrapper = styled.div`
  background: ${props => props.backgroundColor || "#0063ff"};
  height: 60px;
  display: flex;
  padding-left: 15px;
  align-items: center;
`;


const Corpo = styled.section`
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: 15px;
`;

const TituloCorpo = styled.section`
    color: #707070;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    margin: 0px;
    padding-top: 15px;
`;

const TituloCorpo2 = styled.section`
    color: #0063ff;
    color: ${props => props.color || "#0063ff"};
    font-size: 14px;
    font-weight: 700;
    padding-top: 15px;
`;

const TituloAnunciante = styled.section`
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #707070;
`;


const DivPreco = styled.div`
    padding-top: 15px;
    display: flex;
    align-items: center;
`;

const Preco = styled.section`
    color: #000000;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
`;

const OrcamentoMaximo = styled.section`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #707070;
    padding-left: 15px;
`;

const DadosPublicacao = styled.section`
    padding-top: 10px;
    color: #707070;
    font-size: 12px;
`;

const TextoNormal = styled.span`
    color: #707070;
    font-size: 14px;
    font-weight: 400;
`;

const TextoDestaque = styled(TextoNormal)`
    font-weight: 700;
`;

const Anunciante = styled.section`
    margin-top: 15px;
    padding: 10px;
    background-color: #e5e5e58c;
    border-radius: 5px;
`;

const Loading = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`

export default function Invite() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState(null);
    //const [imgSrc, setImgSrc] = useState(null);
    const [imgLojaIsOpen, setImgLojaIsOpen] = useState(true);
    let history = useHistory();

    const { token } = useParams();

    const numberFormat = (value) =>
        new Intl.NumberFormat('pr-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/invites/view?token=${token}`, {
            method: 'GET',
            //mode: 'no-cors',
            headers: new Headers({ 'Authorization': `Bearer ${process.env.REACT_APP_AUTORIZATION}` })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result || result.length === 0 || result.error) {
                        history.push("/notfound");
                    }

                    setIsLoaded(true);
                    setItem(result);
                    document.title = result?.ad_user?.store[0]?.name || 'KiteRadar - Invite';

                    // if (result?.ad_photos[0]?.photo?.url) {
                    //     fetch(result.ad_photos[0].photo.url, {
                    //         method: "GET",
                    //         headers: new Headers({ 'Authorization': `Bearer ${process.env.REACT_APP_AUTORIZATION}` })
                    //     }).then((response) => {
                    //         if (response?.data) {
                    //             const data = `data:${response.headers['content-type']};base64,${Buffer.from(response.data).toString('base64')}`;
                    //             setImgSrc(data);
                    //         }
                    //     });
                    // }
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    return (
        item ? <>
        <Wrapper backgroundColor={item?.ad_user?.store[0]?.theme_color}>
            <Title color={item?.ad_user?.store[0]?.header_color}>
                Invite
            </Title>
        </Wrapper>
        {
            imgLojaIsOpen ?
            <HoverButton 
                _setImgLojaIsOpen = {() => setImgLojaIsOpen(false)} 
            />
            : null
        }
            <Corpo>
                <TituloCorpo2 >Compro</TituloCorpo2>
                <TituloCorpo>
                    {item.title}
                </TituloCorpo>
                <DivPreco>
                    <Preco>{numberFormat(item?.max_price ? item.max_price : 0)}</Preco>
                    <OrcamentoMaximo>Orçamento Máximo</OrcamentoMaximo>
                </DivPreco>
                <DadosPublicacao>Publicado em {new Date(item?.created_at).toLocaleDateString()} as {new Date(item?.created_at).toLocaleTimeString().substring(0, 5)}
                </DadosPublicacao>
                <TituloCorpo2 >Descrição</TituloCorpo2>
                <TextoNormal>{item?.description}</TextoNormal>
                <TituloCorpo2 >Ficha Técnica</TituloCorpo2>
                <div>
                    <TextoDestaque>Ano: </TextoDestaque>
                    <TextoNormal>{item?.max_year}</TextoNormal>
                </div>
                <div>
                    <TextoDestaque>Categoria: </TextoDestaque>
                    <TextoNormal>{item?.category?.description}</TextoNormal>
                </div>
                <div>
                    <TextoDestaque>Marca: </TextoDestaque>
                    <TextoNormal>{item?.brand?.description}</TextoNormal>
                </div>
                <div>
                    <TextoDestaque>Modelo: </TextoDestaque>
                    <TextoNormal>{item?.model?.description}</TextoNormal>
                </div>
                {
                    item?.properties?.map(element => {
                        return <div>
                            <TextoDestaque>{element.propertie_name.description}: </TextoDestaque>
                            <TextoNormal>{element.propertie_value.description}</TextoNormal>
                        </div>
                    })
                }
                {                            
                    (item?.user) ?            
                    <AdvertiserBox
                        advertiserName={item?.user?.name}
                        advertiserLastName={item?.user?.last_name}
                        stateName={item?.user?.user_location?.state?.name}
                        stateUF={item?.user?.user_location?.state?.uf}
                        sinceDate={item?.user?.created_at}
                    /> :
                    null
                }
            </Corpo>
        </> : <Loading>
            <img src="../../loading.gif" alt="" style={{width: "15%"}}/>
        </Loading>);
}