
import React, { useEffect, useState, Component } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import HoverButton from '../components/floatBoxStoreButton';
import AdvertiserBox from '../components/advertiserBox';

require('dotenv').config();

const Title = styled.section`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: ${props => props.color || "#fff"};

`;

const Wrapper = styled.div`
  background: ${props => props.backgroundColor || "#0063ff"};
  height: 60px;
  display: flex;
  padding-left: 15px;
  align-items: center;
`;

const ImgPrincipalDiv = styled.div`  
     display: flex;
     justify-content: center; 
     background-color: #000000;
     overflow: hidden;
     height: 300px;
     `

const AdvImg = styled.img`
    display: inline !important;
    margin: 0 auto !important;
    height: 100% !important;
    width: auto !important;
`;

const ImagemPrincipalMarcaDagua = styled.img`
    bottom: 0;
    left: 0;
    width: 70px !important;
    opacity: 0.5 !important;
    position: absolute !important;
    padding: 5px !important;
`;

const Corpo = styled.section`
    flex-direction: column;
    display: flex;
    justify-content: center;
    margin: 15px;
`;

const TituloCorpo = styled.section`
    color: #707070;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
`;

const TituloCorpo2 = styled.section`
    color: #0063ff;
    color: ${props => props.color || "#0063ff"};
    font-size: 14px;
    font-weight: 700;
    padding-top: 15px;
`;

const Preco = styled.section`
    color: #000000;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
`;

const TextoPequeno = styled.section`
    padding-top: 10px;
    color: #707070;
    font-size: 14px;
`;

const AnuncioProfissional = styled(TextoPequeno)`
    padding-top: 0px;
    padding-bottom: 10px;
    text-align: center;
`;

const TextoNormal = styled.span`
    color: #707070;
    font-size: 14px;
    font-weight: 400;
`;

const TextoDestaque = styled(TextoNormal)`
    font-weight: 700;
`;

const Loading = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`

export default function Adv() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState(null);
    const [imgDim, setImgDim] = useState({ w: 0, h: 0 });
    let history = useHistory();
    const [imgIsOpen, setImgIsOpen] = useState(false);
    const [imgLojaIsOpen, setImgLojaIsOpen] = useState(true);
    const [indexImg, setIndexImg] = useState(0);
    const { token } = useParams();

    const numberFormat = (value) =>
        new Intl.NumberFormat('pr-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/adv/view?token=${token}`, {
            method: 'GET',
            headers: new Headers({ 'Authorization': `Bearer ${process.env.REACT_APP_AUTORIZATION}` })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (!result || result.length === 0 || result.error) {
                        history.push("/notfound");
                    }
                    console.log(result);
                    result?.ad_user?.store[0] && setImgLojaIsOpen(true);

                    setIsLoaded(true);
                    setItem(result);
                    document.title = result?.ad_user?.store[0]?.name || 'KiteRadar - Anúncio';
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [token])

    function fotoClick(index) {
        setIndexImg(index);
        !imgIsOpen && !imgLojaIsOpen && setImgIsOpen(true);
    }

    return (
        item ? <>
            <Wrapper backgroundColor={item?.ad_user?.store[0]?.theme_color}>
                <Title color={item?.ad_user?.store[0]?.header_color}>
                    {item?.ad_user?.store[0]?.name || 'Anúncio'}
                </Title>
            </Wrapper>
            {(item?.ad_photos && item?.ad_photos.length > 0)
                ?
                <>
                    <Carousel
                        // onChange={(i, item) => {
                        //     reactImageSize(item?.props?.children[1]?.props?.src)
                        //         .then(({ width, height }) => {
                        //             setImgDim({ w: width, h: height });
                        //         });
                        // }}
                        statusFormatter={(currentStatus, totalStatus) => <div>{currentStatus} de {totalStatus}</div>}
                        dynamicHeight={false}
                        swipeable={true}
                        showThumbs={false}
                        showStatus={!imgLojaIsOpen}
                        showArrows={!imgLojaIsOpen}
                        autoPlay={false}
                        emulateTouch={true}
                        onClickItem={fotoClick}>
                        {
                            item?.ad_photos.map((foto, index) => (
                                <ImgPrincipalDiv key={index}>
                                    <AdvImg
                                        w={imgDim.w}
                                        h={imgDim.h}
                                        src={foto.photo.url} />
                                    <ImagemPrincipalMarcaDagua h={imgDim.h} src={item?.ad_user?.store[0]?.store_icon?.photo.url}></ImagemPrincipalMarcaDagua>
                                </ImgPrincipalDiv>
                            ))
                        }
                    </Carousel>
                </>
                : null}
            {imgLojaIsOpen ?
                <HoverButton 
                    _setImgLojaIsOpen = {() => setImgLojaIsOpen(false)} 
                />
            : null}
            {imgIsOpen && (
                <Lightbox
                    imageLoadErrorMessage={<div>Não foi possível carregar a imagem.</div>}
                    mainSrc={item.ad_photos[indexImg].photo.url}
                    nextSrc={item.ad_photos[(indexImg + 1) % item.ad_photos.length].photo.url}
                    prevSrc={item.ad_photos[(indexImg + item.ad_photos.length - 1) % item.ad_photos.length].photo.url}
                    onCloseRequest={() => setImgIsOpen(false)}
                    onMovePrevRequest={() =>
                        setIndexImg((indexImg + item.ad_photos.length - 1) % item.ad_photos.length)
                    }
                    onMoveNextRequest={() =>
                        setIndexImg((indexImg + 1) % item.ad_photos.length)
                    }
                />
            )}
            <Corpo>
                {item?.ad_user?.store[0] ?
                    <AnuncioProfissional>Anúncio Profissional.</AnuncioProfissional>
                    : null}
                <TituloCorpo>
                    {item.title}
                </TituloCorpo>
                <Preco>{numberFormat(item?.price ? item.price : 0)}</Preco>
                <TextoPequeno>Publicado em {new Date(item?.created_at).toLocaleDateString()} as {new Date(item?.created_at).toLocaleTimeString().substring(0, 5)}
                </TextoPequeno>
                <TituloCorpo2 color={item?.ad_user?.store[0]?.text_color}>Descrição</TituloCorpo2>
                <TextoNormal>{item?.description}</TextoNormal>
                <TituloCorpo2 color={item?.ad_user?.store[0]?.text_color}>Ficha Técnica</TituloCorpo2>
                <div>
                    <TextoDestaque>Ano: </TextoDestaque>
                    <TextoNormal>{item?.year}</TextoNormal>
                </div>
                <div>
                    <TextoDestaque>Categoria: </TextoDestaque>
                    <TextoNormal>{item?.ad_category?.description}</TextoNormal>
                </div>
                <div>
                    <TextoDestaque>Marca: </TextoDestaque>
                    <TextoNormal>{item?.ad_brand?.description}</TextoNormal>
                </div>
                <div>
                    <TextoDestaque>Modelo: </TextoDestaque>
                    <TextoNormal>{item?.ad_model?.description}</TextoNormal>
                </div>
                {
                    item?.properties?.map(element => {
                        return <div>
                            <TextoDestaque>{element.propertie_name.description}: </TextoDestaque>
                            <TextoNormal>{element.propertie_value.description}</TextoNormal>
                        </div>
                    })
                }
                <TituloCorpo2 color={item?.ad_user?.store[0]?.text_color}>Localização do Anúncio</TituloCorpo2>
                <div>
                    <TextoDestaque>CEP: </TextoDestaque>
                    <TextoNormal>{item?.ad_location?.zip_code}</TextoNormal>
                </div>
                <div>
                    <TextoDestaque>Cidade: </TextoDestaque>
                    <TextoNormal>{item?.ad_location?.city?.name}</TextoNormal>
                </div>
                <div>
                    <TextoDestaque>Estado: </TextoDestaque>
                    <TextoNormal>{item?.ad_location?.state?.name}/{item?.ad_location?.state?.uf}</TextoNormal>
                </div>
                {                            
                    (item?.ad_user?.store[0]) ?            
                    <AdvertiserBox
                        advertiserName={item?.ad_user?.store[0]?.name}
                        advertiserLastName={item?.ad_user?.store[0]?.last_name}
                        stateName={item?.ad_user?.store[0]?.store_location?.state?.name}
                        stateUF={item?.ad_user?.store[0]?.store_location?.state?.uf}
                        sinceDate={item?.ad_user?.created_at}
                    /> :
                    null
                }
                {                            
                    (item?.ad_user) ?            
                    <AdvertiserBox
                        advertiserName={item?.ad_user?.name}
                        advertiserLastName={item?.ad_user?.last_name}
                        stateName={item?.ad_user?.user_location?.state?.name}
                        stateUF={item?.ad_user?.user_location?.state?.uf}
                        sinceDate={item?.ad_user?.created_at}
                    /> :
                    null
                }
            </Corpo>
        </> : <Loading>
            <img src="../../loading.gif" alt="" style={{width: "15%"}}/>
        </Loading>);
}