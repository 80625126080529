import React from "react";
import Accreditation from "./accreditation";
import Adv from "./adv";
import Invite from "./invite";
import Occurrence from "./occurrence";
import Spotlight from "./spotlight";
import NotFound from "./notFound";
import Expired from "./expired";
import Download from "./download";
import Dashboard from "./dashboard";
import Register from "./register";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  Redirect,
} from "react-router-dom";

require("dotenv").config();

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/v1/accreditation/:token">
          <Accreditation />
        </Route>
        <Route path="/radar/:location">
          <Dashboard />
        </Route>
        <Route path="/v1/dashboard/:token">
          <Dashboard />
        </Route>
        <Route path="/v1/adv/:token">
          <Adv />
        </Route>
        <Route path="/v1/occurrence/:token">
          <Occurrence />
        </Route>
        <Route path="/v1/spotlight/:token">
          <Spotlight />
        </Route>
        <Route path="/v1/invite/:token">
          <Invite />
        </Route>
        <Route path="/notfound">
          <NotFound />
        </Route>
        <Route path="/expired">
          <Expired />
        </Route>
        <Route path="/download">
          <Download />
        </Route>
        <Route path="/v1/register/:url_alias">
          <Register />
        </Route>
        <Route path="/v1/store/:token">
          <Redirect to="/download" />
        </Route>
        <Route exact path="/">
          <Redirect to="/notfound" />
        </Route>
        <Route path="*">
          <Redirect to="/notfound" />
        </Route>
      </Switch>
    </Router>
  );
}

// function Home() {
//   return (
//     <NotFound />
//   );
// }
